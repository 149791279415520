
<template>
  <div>
    <MainHeader />
    <div v-if="isLoading" class="loading-spinner">
        <div class="lds-ring"><div></div><div></div><div></div><div></div></div>
    </div>
    <div class="px-md-0 px-3">
      <div class="container">
        <ul class="breadcrumb">
          <li>página inicial</li>
          <li>dados do humano</li>
          <li>agentes</li>        
          <li>confirmação agentes</li>        
          <li>confirmação humano</li>        
        </ul>
        <div class="mt-3">
          <h1>Revise os dados do seu humano!</h1>
          <p>confira se todos os dados estão corretos para impressão do crachá. se precisar corrigir, é só clicar em "editar dados".</p>
        </div>
      </div>
    </div>
    <div class="bg-2">
        <div class="container">
          <h2 v-if="!cdsg_store" class="d-block  px-3 px-md-0">Dados para Envio do(s) crachá(s)</h2>
          <h2 v-else class="d-block  px-3 px-md-0">Dados do humano</h2>
        </div>
    </div>
    <div class="container">
      <div class="revisionData my-5 px-4 px-md-0">
        <p>{{human.first_name}} {{human.last_name}}</p>
        <p>CPF {{human.doc}}</p>
        <p>{{human.address}}, {{human.address_number}}</p>
        <p>Bairro: {{human.neighborhood}}</p>
        <p>{{human.city}}/{{human.state}}</p>
        <br><br>
        <p>{{human.phone}}</p>
        <p>{{human.email}}</p>
      </div>
      <div class="row mb-5 pb-5 mt-3 btns">
        <div class="col-md-auto col-10 mb-md-0 mb-3">
          <router-link :to="{ name: 'HumanDMF' }" class="btn btn-secondary"><span class="d-block mx-4 my-2">Editar Dados</span></router-link>
        </div>
        <div class="col-md-auto col-10">
          <button @click="saveToCms()" class="btn btn-primary"><span class="d-block mx-5 my-2">Finalizar</span></button>
      </div>
      </div>
    </div>
  </div>
</template>

<script>
import MainHeader from "@/components/MainHeader.vue";
import axios from 'axios' 
const apiUrl = 'https://cms-cdsg.up.railway.app/api';
//const apiUrl = 'https://cms-cdsg.up.railway.app/api';
//const apiUrl = 'http://localhost:1337/api';';
export default {
  name: 'RevisionHuman',
  components: {
    MainHeader,
    
  }, 
  data() {
    return {
      human:{},
      isLoading:false,
      cdsg_store: null,
      active: false,
      count: 0
    };
  },
  methods:{
    async allowForm() {
      const urlObj = new URL(window.location.href);
      const params = new URLSearchParams(urlObj.search);
      const stToken = params.get('st_token') || sessionStorage.getItem('st_token');
      if (stToken) {
        try {
          const response = await axios.get(`${apiUrl}/dmf-stores?filters[url_key][$eq]=${stToken}&filters[active][$eq]=true`);
          if (response && response.data && response.data.data && response.data.data[0]) {
            sessionStorage.setItem('st_token', stToken);
            this.cdsg_store = response.data.data[0];
            console.log(this.cdsg_store)
          } else {
            sessionStorage.removeItem('st_token');
          }
        } catch (error) {
          console.log(error);
          sessionStorage.removeItem('st_token');
        }
      } else {
        sessionStorage.removeItem('st_token');
      }

      try {
        const response = await axios.get(`${apiUrl}/lp-dmf`);
        this.active = response.data.data.attributes.active;
      } catch (error) {
        console.log(error);
      }

      if (!this.cdsg_store && !this.active && sessionStorage.getItem('bd') != 1) {
        window.location.href = "/";
      }

      try {
        const resp1 = await axios.get(`${apiUrl}/dmf-agent-count`);
        this.count = resp1.data.data.attributes.count;
      } catch (error) {
        console.log(error);
      }
    },  
    async saveToCms(){  
      this.isLoading = true   
      let human = JSON.parse(sessionStorage.getItem('dmf_human'));
      let agents = JSON.parse(sessionStorage.getItem('dmf_agents'));   
      let data = { data: {
        human: human,
        agents: agents,
        privacy_terms_read: true,
        accept_newsletter: true,
        dmf_store: this.cdsg_store.id || null,
        publishedAt:null
      }}
      await axios.post(`${apiUrl}/lp-dmf-responses`,data)
      .then(response => {
          console.log(response)
          sessionStorage.setItem(`li_id`,human.doc)
          sessionStorage.removeItem(`dmf_human`)
          sessionStorage.removeItem(`dmf_agents`)
          this.$router.push('/obrigado')      
      })
      .catch(error => {
          console.log(error);
      });
      this.isLoading = false
      
    },
  },
  mounted(){
    let storedHuman = sessionStorage.getItem('dmf_human')
    if(storedHuman){
      this.human = JSON.parse(storedHuman)
    }
  },
  async created(){
    this.allowForm()
  }
};
</script>
<style scoped>
  h1{color:var(--color2);font-weight:900;
  text-transform:uppercase;font-size:34px;margin: 0 0 1rem 0;}
  h1 + p{
    font-weight: 600;
    margin-bottom: 2rem;
    color: #000;
  }
  h2{
    color: var(--black);
    font-size: 24px;
    text-transform: uppercase;
    font-weight: 900;
    padding: 1.5rem 0 1.8rem 0;
  }

  .btn-secondary{background: var(--color2);color:var(--black)}
  @media(max-width:990px){
    h1{
      font-size: 22px;
    }
    h1 + p{font-size: 14px;}
    h2{
      padding: 1rem 0 1.2rem 0;
      font-size: 16px;
    }
    .revisionData p{font-size: 14px;}
    .btn.btn-primary{width: 100%;}
    .btns{justify-content:center}
    .btn{width: 100%;display:block;}
  }
</style>